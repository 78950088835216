import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      cartoonStories: "Cartoon Story &",
      minigames: "Minigames",
      download: "Download"
    },
  },
  uk: {
    translation: {
      cartoonStories: "Казкові історії та",
      minigames: 'Міні-ігри',
      download: "Завантажити"
    },
  },
  es: {
    translation: {
      cartoonStories: "Cuentos de hadas y",
      minigames: 'Minijuegos',
      download: "Descargar"
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;