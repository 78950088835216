import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const MetaPixel = ({ pixelId }) => {
  useEffect(() => {
    ReactPixel.init(pixelId);
  }, [pixelId]);

  return null;
};

export default MetaPixel;