import "./App.css";
import Download from "./pages/download/Download";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import background from "./assets/background.svg";
import './fonts/fonts.css';

const StyledBox = styled(Box)({
  width: "100%",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

function App() {
  return (
    <StyledBox>
      <Download />
    </StyledBox>
  );
}

export default App;
