import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled, keyframes } from "@mui/system";
import { ReactComponent as LogoSVG } from "../../assets/logo.svg";
import { ReactComponent as RabbitSVG } from "../../assets/rabbit.svg";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import download from "../../assets/download.svg";
import hover_download from "../../assets/hover_download.svg"
import { ReactComponent as HandSVG } from "../../assets/hand.svg";
import { useTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";

const moveHand = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  25% {
    transform: translate(-50px, -50px);
    opacity: 1;
  }
  50% {
    transform: translate(-50px, -50px);
    opacity: 1;
  }
  75% {
    transform: translate(-50px, -50px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 0;
  }
`;

// Анімація збільшення кнопки
const scaleButton = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const StyledText = styled(Typography)({
  fontSize: "72px",
  fontFamily: "GoldPlay, Arial, sans-serif",
  fontWeight: "bold",
  "@media (max-width:600px)": {
    fontSize: "30px",
  },
});

const WhiteStyledText = styled(StyledText)({
  color: "#FFF",
  textAlign: "center",
  WebkitTextStrokeWidth: 4,
  WebkitTextStrokeColor: "#218084",
  fontStyle: "normal",
  lineHeight: "72px",
  "@media (max-width:600px)": {
    WebkitTextStrokeWidth: 2,
  },
});

const YellowStyledText = styled(StyledText)({
  color: "#EDFF23",
  textShadow: "5px 8px 6.1px rgba(33, 128, 132, 0.77)",
});

const StyledButton = styled(Button)(({ isScaling }) => ({
  backgroundImage: `url(${download})`,
  width: "320px",
  height: "120px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  animation: isScaling ? `${scaleButton} 2s` : "none",
  "@media (max-width:600px)": {
    width: "210px",
    height: "80px",
  },
  "&:hover": {
    backgroundImage: `url(${hover_download})`,
  },
}));

const StyledHandSVG = styled(HandSVG)({
  position: "absolute",
  top: "100%",
  transform: "translate(-50%, -50%)",
  animation: `${moveHand} 3s infinite`,
});

const StyledLogoSVG = styled(LogoSVG)({
  width: "200px",
  height: "200px",
  "@media (max-width:600px)": {
    width: "150px",
    height: "150px",
  },
});

const StyledRabbitSVG = styled(RabbitSVG)({
  width: "100%",
  height: "100%",
  "@media (max-width:600px)": {
    width: "75%",
    height: "75%",
  },
});

const StyledDownloadText = styled(Typography)({
  color: '#FFF',
  textShadow: '-1px 2px 0px #2E99C7',
  fontFamily: "Chalkboard SE",
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '48px',
  position: 'relative',
  bottom: '5px',
  "@media (max-width:600px)": {
    fontSize: "25px",
  },
})

const Download = () => {
  const { t } = useTranslation();
  const [isScaling, setIsScaling] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsScaling(true);
      setTimeout(() => setIsScaling(false), 2500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const onDownload = () => {
    ReactPixel.track("Lead");
    window.location.href =
      "https://apps.apple.com/app/id6443717816?ppid=a89d31b9-3d21-453a-9b65-40401e4c6db0";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        textAlign: "center",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "md",
          width: "100%",
          flexDirection: { xs: "column", lg: "row" },
          minHeight: { xs: "65vh", lg: "50vh" },
        }}
      >
        <Grid
          item
          xs={12}
          lg={4}
          container
          justifyContent="center"
          alignItems="center"
        >
          <StyledLogoSVG />
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <WhiteStyledText variant="h1">
              {t("cartoonStories")}
            </WhiteStyledText>{" "}
            <YellowStyledText>{t("minigames")}</YellowStyledText>
          </Box>
        </Grid>
        <Grid item xs={12} mt={2} position="relative">
          <StyledButton
            isScaling={isScaling}
            onClick={onDownload}
          >
          <StyledDownloadText>{t("download")}</StyledDownloadText>
          </StyledButton>
          <StyledHandSVG />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "auto" }}>
        <StyledRabbitSVG />
      </Grid>
    </Box>
  );
};

export default Download;
